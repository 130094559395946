html,

body {
    margin: 0;
    padding: 0;
    background-color: #FBFBFB;
}

.container {
    border-radius: 8px;
    height: 100vh;
    display: flex;
    background-color: #FBFBFB;
    width: 20vw;
    margin-left: 0;
}

@media (max-width: 450px) {
    .sidebar-container{
        display: none;
    }
}

.image {
    width: 40;
    height: 40;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
}

.subContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-left: 18px; */
}

.menuContainer {
    /* width: 256px; */
    padding: 10px;
    border-radius: 8px;
}

.title {
    padding-left: 12px;
    color: black;
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
}

.itemsContainer {
    display: flex;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
}

.menuItem {
    padding-left: 18px;
    background-color: white;
}

.span {
    padding-left: 12px;
    color:  #201C1C;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.menuItemContainer {
    padding: 0px;
    border-radius: 8px;
}

.subMenuContainer {
    color: black;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
}

.logo {
    object-fit:contain;
    height: 30px;
}

.subContainerItem {
    border-radius: 8px;
}

.sidebar {
    height: 100vh;
    padding: 20px;
    border-right-style: none;
    overflow: hidden;
    top: 0;
    bottom: 0;
}

.menu {
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    height: 90vh;
}

.profileContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.profileIcon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.profileName {
    color: black;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
}

.profileSubTitle {
    color: gray;
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 400;
}

.accordion-title {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
    padding: 0
        /* 100% */
}