.user-details-input {
  width: 47.87vw;
  height: 32.07vh;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.order-info {
  max-height: 60vh;
  overflow-y: auto;
}
