.customers-input-container {
  width: 22.47vw;
  height: 6.54vh;
  border-radius: 8px;
  background-color: #fbfbfb;
  border: 0;
  padding-left: 30px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: #201c1c3d;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
}
