html,

body {
    margin: 0;
    padding: 0;
}

.container {
    background-color: #FBFBFB;
    height: 100%;
    flex-direction: column;
}

.title-heading {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 0;
    padding-top: 20px;
}

.promo-code-header {
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    margin: 0;
}

.promo-table-container {
    width: 80vw;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-top: 24px;
    padding-top: 24px;
    padding-left: 16px;
    /* padding-right: 16px; */
    padding-bottom: 24px;
}

.promo-table-sub {
    display: flex;
    align-items: center;
}

.promo-code-input-container {
    width: 348.51px;
    height: 45.45px;
    border-radius: 28px;
    background-color: #FBFBFB;
    border: 0;
    padding-left: 40px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: #201C1C3D;
}
.add-promo-container{
    width: 15.51vw;
    height: 24,22vh;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 20px;
}
.add-promo-image-container{
    background-color: #FBFBFB;
    padding: 14px;
    border-radius: 45px;
    width: 52px;
    height: 52px;
}