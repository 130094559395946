* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'poppins';
}

.app-container {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
}

.sidebar-container {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 20.49vw;
  z-index: 1000;
}

.common-button-blue {
  width: 16.69vw;
  height: 5.89vh;
  background-color: #8B5CFF;
  border-radius: 8px;
  border: 0;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 16px;
  cursor: pointer;
}

.common-button-cancel {
  width: 11.71vw;
  height: 5.89vh;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: 0;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #F34A4A;
  margin-top: 16px;
  margin-left: 16px;
  cursor: pointer;
}

.delete-btn-item {
  margin: 0;
  width: 5.63vw;
  height: 5.56vh;
  background-color: #F34A4A;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  border: 0;
  margin-left: 8px;
  margin-right: 16px;
}

.reimport-btn {
  margin: 0;
  width: 7.83vw;
  height: 3.56vh;
  background-color: #205DE83D;
  border-radius: 8px;
  color: #8B5CFF;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  margin-right: 8px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #8B5CFF;
  border-radius: 5px;
}

/* Style the scrollbar track (background) on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #8B5CFF
}