.container-create-password {
    width: 40rem;
}

.continue-button-create-pass {
    width: 40rem;
}
.textfield-create-pass {
    width: 40rem
}

/* Responsive adjustments for small screens */
@media (max-width: 450px) {
    .container-create-password {
        width: 20rem;
    }
    
    .continue-button-create-pass {
        width: 20rem;
    }

    .textfield-create-pass {
        width: 20rem
    }

    .subtext {
        justify-content: center;
    }
}

@media (min-width: 1280px) and (max-width: 1920px) {
    .container-create-password {
        width: 40rem;
    }
    
    .continue-button-create-pass {
        width: 40rem;
    }
    .textfield-create-pass {
        width: 40rem
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .container-create-password {
        width: 30rem;
    }
    
    .continue-button-create-pass {
        width: 30rem;
    }
    .textfield-create-pass {
        width: 30rem
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .container-create-password {
        width: 25rem;
    }
    
    .continue-button-create-pass {
        width: 25rem;
    }
    .textfield-create-pass {
        width: 25rem
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .container-create-password {
        width: 25rem;
    }
    
    .continue-button-create-pass {
        width: 25rem;
    }
    .textfield-create-pass {
        width: 25rem
    }
}

@media (min-width: 600px) and (max-width: 768px) {
    .container-create-password {
        width: 20rem;
    }
    
    .continue-button-create-pass {
        width: 20rem;
    }
    .textfield-create-pass {
        width: 20rem
    }
}

@media (min-width: 540px) and (max-width: 600px) {
    .container-create-password {
        width: 20rem;
    }
    
    .continue-button-create-pass {
        width: 20rem;
    }
    .textfield-create-pass {
        width: 20rem
    }
}