.mainContainer {
    padding-top: 20px;
    padding-left: 15px;
    text-align: start;
    padding-bottom: 20px;
}

.display-mian-content {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 32px; */
    /* 114.286% */
    margin: 0;
}

.display-mian-sub-content {
    color: rgba(32, 28, 28, 0.48);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 1px; */
    /* 114.286% */
    margin: 0;
}

.display-content {
    font-weight: bold;
    font-size: 21px;
    font-family: 'Poppins';
    margin: 0;
    letter-spacing: 1px;
}

.display-sub-content {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    color: #858585;
    font-weight: normal;
}

.mainbox {
    background-color: white;
    height: auto;
    margin-top: 20px;
    padding: 10px 0px 10px 15px;
    width: 62%;
    border-radius: 8px;
}

.checkbox-label {
    display: flex;
    margin-top: 10px;
    gap: 7px;
    font-weight: normal;
    font-family: 'Poppins';
    color: #201C1C;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 200% */
    text-transform: capitalize;
}

.advance-content {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 16px; */
    margin: 0;
    /* 133.333% */
    text-transform: capitalize;
}

.display-advance-sub-content {
    color: rgba(32, 28, 28, 0.48);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 16px; */
    margin: 0;
    padding: 0;
    width: 42.45vw;
    /* 114.286% */
}

.labelForDisplay {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 200% */
    text-transform: capitalize;
}

.about-item {
    font-size: 10px;
    font-family: 'Poppins';
    margin: 0;
    color: #b5b4b4;
    padding: 0;
    font-weight: 400;
    width: 44.94vw;
}

.cards {
    background-color: white;
    height: auto;
    margin-top: 15px;
    padding: 8px 0px 10px 15px;
    width: 62%;
    border-radius: 8px;

}

.instructions {
    font-size: 11px;
    font-family: 'Poppins';
    margin: 0;
    font-weight: 500;
}

.checkbox-content {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    font-weight: normal;
}

.input-sub-lable {
    font-size: 10px;
    font-family: 'Poppins';
    margin: 0;
    color: #b5b4b4;
    font-weight: normal;
    margin-top: 4px;
    width: 44.94vw;
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* opacity: 0.4; */
}

.upload-button {
    display: flex;
    padding: 8px 16px 8px 12px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    background: rgba(32, 93, 232, 0.24);
    color: #8B5CFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
    text-transform: capitalize;
    width: 100%;
}

.remove-button {
    display: flex;
    padding: 8px 16px 8px 12px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    background: #F34A4A;
    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    text-transform: capitalize;
    width: 150px;
    border: none;
}

.card-title {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 200% */
    text-transform: capitalize;
    margin: 0;
}

.business-button {
    margin-top: 10px;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #8B5CFF;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    text-transform: capitalize;
    border: none;
}