.heading-referrals {
    font-weight: 600;
    font-size: 28px;
    font-family: 'Poppins';
    margin: 0;
    padding-top: 20px;
}

.reffer-container {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    width: 47.29vw;
    justify-content: space-between;
}

.text-refer-cont {
    align-items: center;

}

.btn-container-referral {
    display: flex;
    align-items: center;
}

.refer-buddy {
    font-weight: 600;
    font-size: 24px;
    font-family: 'Poppins';
    margin: 0;
}

.reffer-about {
    font-size: 14px;
    color: #201C1C7A;
    font-weight: 400;
    margin: 0;
    font-family: 'Poppins';
}

.btn-url {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    font-size: 14px;
    height: 5.83vh;
    width: 8.27vw;
    border-radius: 8px;
    margin-left: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    border: none;
}

.label-input {
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    font-family: 'Poppins';
    margin: 0;
}

.referral-input {
    width: 44.94vw;
    height: 7.13vh;
    margin-top: 1px;
    border: 1px solid #201C1C3D;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    margin-top: 8px;
    padding-left: 12px;
    font-family: 'Poppins';
}


.maincontainer-referrel {
    background-color: white;
    margin-top: 10px;
    width: 47.29vw;
    border-radius: 8px;
    text-align: left;
}

.container-referrel {
    margin-left: 20px;
    padding-top: 10px;
}

.about-referrel {
    font-size: 12px;
    color: #201C1C70;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.paymentType-btn {
    width: 26.35vw;
    height: 6.48vh;
    margin-top: 10px;
    border-radius: 8px;
    background-color: #201C1C14;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Poppins';
    padding-left: 16px;
    text-align: left;
    border: none;
}

.sendans-btn {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #8B5CFF;
    font-size: 14px;
    height: 33px;
    width: 160px;
    border-radius: 8px;
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: "Poppins";
    border: none;
}