.manage {
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 28px;
    letter-spacing: 1px;
    text-align: start;
    padding-top: 16px;
    margin: 0;
}

.about {
    font-size: 12px;
    color: #b5b4b4;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
    text-align: start;
}

.info-txt {
    font-weight: 600;
    font-family: 'Poppins';
    margin: 0;
    font-size: 18px;
    letter-spacing: 1px;
    text-align: start;
}

.label-setting {
    font-size: 14px;
    margin: 0;
    font-family: 'Poppins';
    font-weight: 600;
}

.setting-input {
    width: 44.94vw;
    height: 7.13vh;
    margin-top: 1px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 0;
    font-size: 12px;
    padding-left: 12px;
    font-family: 'Poppins';
    color: #201C1C;
    outline: none;

}

.labelinput-div {
    margin-left: 20px;
    padding-top: 10px;
}

.maincontainer {
    background-color: white;
    margin-top: 10px;
    width: 62%;
    border-radius: 8px;
    text-align: left;
}

.box-about {
    padding-top: 15px;
    margin-left: 20px;
}

.subscriptions {
    font-size: 12px;
    font-weight: 500;
    color: #201C1C70;
    margin: 0;
    font-family: 'Poppins';
}

.edit-btn {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    font-size: 13px;
    height: 36px;
    width: 150px;
    border-radius: 8px;
    margin-top: 3px;
    margin-left: 20px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.cancel-subscription {
    cursor: pointer;
    color: #F34A4A;
    background-color: #F34A4A14;
    font-size: 14px;
    height: 33px;
    width: 160px;
    border-radius: 16px;
    margin-top: 3px;
    margin-left: 20px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.changePassword {
    cursor: pointer;
    color: #8B5CFF;
    /* Text color */
    background-color: #eef2fe;
    /* Background color */
    font-size: 13px;
    height: 33px;
    width: 160px;
    border-radius: 8px;
    margin-top: 3px;
    margin-left: 20px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.typesofbilling {
    font-weight: 600;
    font-family: 'Poppins';
    margin: 0;
    font-size: 21px;
    letter-spacing: 1px;
    text-align: start;
}

.billinginfo-btn {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    font-size: 13px;
    height: 33px;
    width: auto;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;

}

.subscriptionplan-btn {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    font-size: 13px;
    height: 33px;
    width: 190px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.mainBox-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    background-color: white;
    border-radius: 8px;
    padding: 10px;

}

.success-mainbox {
    padding-top: 10px;
    text-align: center;

}

.successmsg {
    padding: 10px 0px;
    font-size: 12px;
    color: #b5b4b4;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.import-btn-settings {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    text-align: center;
    font-size: 12px;
    height: 33px;
    width: 150px;
    border-radius: 8px;
    margin-top: 3px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.heading-img {
    display: flex;
    gap: 55%;
    text-align: left;
}

.subheading {
    font-weight: 600;
    font-family: 'Poppins';
    margin: 0;
    letter-spacing: 1px;
}

.cross-img {
    height: 20px;
    border-radius: 50%;
    background-color: #fde2e2;
    cursor: pointer;
}

.aboutNewmenu {
    font-size: 10px;
    color: #b5b4b4;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
    margin-top: 5px;
}

.headingforinput {
    font-size: 11px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.import_btn {
    margin-right: 40px;
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    text-align: center;
    font-size: 12px;
    height: 30px;
    width: 170px;
    border-radius: 6px;
    margin-top: 3px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.cancel {
    cursor: pointer;
    color: #FF0000;
    /* Red color (replace with desired color) */
    text-align: center;
    font-size: 12px;
    border-radius: 6px;
    margin-top: 3px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.done-btn {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    text-align: center;
    font-size: 12px;
    height: 33px;
    width: 150px;
    border-radius: 8px;
    margin-top: 3px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
}

.miniosk-image-container {
    position: relative;
    top: 70;
    left: 120;
    background-color: #8B5CFF;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}

.profile-main-img-container {
    padding-top: 16px;
}