html,

body {
    margin: 0;
    padding: 0;
}

.container {
    background-color: #FBFBFB;
    height: 100%;
    flex-direction: column;
}

.sub-title-container {
    width: 580px;
}

.catalog-sub-title {
    color: #201C1C7A;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
    margin-top: 0;
}

.catalog-title-heading {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 0;
    /* padding-top: 20px; */
}

.menu-container {
    width: 78vw;
    height: 58.9vh;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.menu-title {
    margin-top: 16px;
    margin-bottom: 0;
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins';
}

.menu-card {
    margin-top: 16px;
    margin-left: 16px;
    height: 88px;
    background-color: #F9F9F9;
    /* width: 15.15vw; */
    width: 207px;
    border-radius: 8px;
    border: 1px #201C1C52;
}

.menu-sub-title {
    margin-left: 16px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    margin-bottom: 0;
    /* padding-top: 16px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "150px", */
}

.distance-title {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    color: #201C1C70;
    padding-left: 16px;
}

.time-title {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    font-family: 'Poppins';
    color: #201C1C70;
    padding-left: 16px;
}

.menu-card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.info {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins';
}

.info-container {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    padding-left: 16px;
}

.info-desc {
    padding-left: 8px;
    margin: 0;
    font-weight: 400;
    font-size: 10px;
    font-family: 'Poppins';
    color: #201C1CB8;
    width: 25.10vw;
}

.import-menu {
    width: 143px;
    height: 36px;
    justify-content: center;
    align-items: center;
    background-color: #8B5CFF;
    color: #FFFFFF;
    border-width: 0px;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    margin-left: 16px;
    border-radius: 8px;
    margin-top: 16px;
    transition: "background-color 0.3s"
}

.import-title-container {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.import-title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Poppins';
    color: #201C1C;
}

.menu-details {
    padding-top: 16px;
    margin: 0;
}

.menu-heading {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Poppins';
    color: #201C1C;
    /* padding-top: 16px; */
}

.input-container {
    margin-top: 8px;
    width: 44.94vw;
    height: 7.20vh;
    border-radius: 8px;
    border-width: 1px;
    border-color: #201C1C3D;
    padding: 10px 16px 10px 16px;
    outline: none;
    font-family: 'Poppins';
}

.input-sub-text {
    margin: 0;
    font-weight: 400;
    font-size: 10px;
    font-family: 'Poppins';
    color: #201C1C70;
    padding-top: 4px;
}

.option {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    padding: 10px;
}

.check-box {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Poppins';
    color: #000000;
}

.checkbox-sub-text {
    margin: 0;
    font-weight: 400;
    font-size: 10px;
    font-family: 'Poppins';
    color: #201C1C70;
    padding: 0;
}

.import-menu-btn {
    display: flex;
    flex-direction: row;
}

.import-btn {
    width: 256.3px;
    height: 40.93px;
    justify-content: center;
    align-items: center;
    background-color: #8B5CFF;
    color: #FFFFFF;
    border-width: 0px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Poppins';
    border-radius: 8px;
    margin-top: 16px;
}

.cancel-btn {
    width: 256.3px;
    height: 40.93px;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    color: #F34A4A;
    border-width: 0px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Poppins';
    border-radius: 8px;
    margin-top: 16px;
    margin-left: 16px;
}

.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.success-header {
    font-weight: 600;
    font-size: 24px;
    font-family: 'Poppins';
    margin: 0;
}

.success-desc {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    color: #201C1C7A;
    margin: 0;
}

.done-btn {
    width: 12.2vw;
    height: 5.89vh;
    justify-content: center;
    align-items: center;
    background-color: #8B5CFF;
    color: #FFFFFF;
    border-width: 0px;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    border-radius: 8px;
    margin-top: 16px;
}