.heading-support{
    margin: 0;
    font-weight: 600;
    font-size: 28px;
    font-family: 'Poppins';
    padding-top: 20px;
   
}
.main-container-support{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.main-content-support{
    margin: 0;
    font-weight: 600;
    font-size: 17px;
    font-family: 'Poppins';
    /* padding-top: 30px; */
    color: #111111;
    text-align: center;
}
.card-container-support{
    width: 25.62vw;
    /* height: 44.25vh; */
    background-color: #FFFFFF;
    border-radius: 32px;
    /* padding: 25px, 10px, 25px, 10px; */
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    /* margin-bottom: 140.46px; */
}
.vectorImg_div{
    width: 47.69px;
    height: 47.69px;
    background-color: #8B5CFF;
    border-radius: 60.92px;
    /* padding: 11.85px; */
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    display: flex;
    margin-left: 10px;
}
.contactType{
    margin: 0;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 14px;
    color: #000000;
    padding-left: 20px;
    margin-top: 25px;
}
.contact{
    margin: 0;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 12px;
    color: #666666;
    padding-left: 20px;
}