html,

body {
    margin: 0;
    padding: 0;
}

.Link-account-container {
    background-color: #FBFBFB;
    height: 100%;
    flex-direction: column;
}

.think-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.pos-account {
    margin: 0;
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: '600';
    color: #111111;
}

.pos-accounts-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    align-items: center;

}

.account-container {
    width: 21.59vw;
    height: 27.87vh;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 36px, 43px, 36px, 43px;
    margin: 16px;
}

.link-account-btn {
    width: 184.4px;
    height: 37.18px;
    background-color: #8B5CFF;
    border-radius: 8px;
    border: 0;
    font-size: 12.64px;
    font-weight: 400;
    font-family: "Poppins";
    color: #FFFFFF;
    margin-top: 21.66px;
}