html,

body {
    margin: 0;
    padding: 0;
}

.container-login {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
}

.main-container-login {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* padding-left: 55px; */
}

.miniosk-login {
    color: #201C1C;
    font-weight: 600;
    font-size: 24px;
    font-family: "Poppins";
    margin: 0;
    width: 30vw;

}

.miniosk-login-desc {
    color: #201C1CB8;
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins";
    margin: 0;
    padding-top: 5px;
}

.email-miniosk {
    color: #201C1CB8;
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins";
    margin: 0;
    padding: 30px 0px 6px 0px;

}

.miniosk-input {
    width: 30vw;
    height: 8.5vh;
    border-radius: 8px;
    border: 1px solid #201C1C3D;
    color: black;
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins";
    padding-left: 12px;
}

.miniosk-header {
    justify-content: center !important;
    display: flex !important;
    align-items: flex-end !important;
}

.continue-password-button {
    width: 30vw;
    height: 8vh;
    border-radius: 8px;
    border-width: 1px;
    cursor: pointer;
    color: white;
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins";
    margin-top: 20px;
    background-color: #8B5CFF;
    border: none;
}
.forgotpassword{
    color:#8B5CFF ;
    font-weight: 100;
    cursor: pointer;
    font-size:13px ;
    padding-top:10px;
    font-family: 'Poppins';
    margin: 0;
}
.kiosk_image_logo{
    margin-top: 3rem;
}
.miniosk-img{
    width: 30px;
}
.humanly-text{
    font-size: 15px;
    font-weight: bold;
    font-family: 'Poppins';
}

.grid-container-password {
    padding: 8vh;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
}

/* Responsive adjustments for small screens */
@media (max-width: 450px) {
    .grid-container-password {
        padding: 2vh;
        /* height: 50vh; */
    }
    .kiosk_image_logo{
        margin-bottom: 5rem;
    }
}

@media (min-width: 1280px) and (max-width: 1920px) {
    .grid-container-password {
        padding: 4vh;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .grid-container-password {
        padding: 4vh;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .grid-container-password {
        padding: 4vh;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .grid-container-password {
        padding: 4vh;
    }
}

@media (min-width: 600px) and (max-width: 768px) {
    .grid-container-password {
        padding: 1vh;
    }
}