html,
body {
  margin: 0;
  padding: 0;
}

.container-register {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  height: 100vh;
}

.main-container-register {
  width: 40%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding-left: 42px; */
  /* padding: 0 25px; */
}
.register-form {
  height: calc(100vh - 40px);
  overflow-y: auto;
  max-width: 30vw;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  /* padding: 12px 0; */
}

.miniosk-register {
  color: #201c1c;
  font-weight: 600;
  font-size: 22px;
  font-family: "Poppins";
  margin: 0;
}
.miniosk-register-desc {
  color: #201c1cb8;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  margin: 0;
  padding-top: 5px;
}

.email-miniosk {
  color: #201c1cb8;
  font-weight: 400;
  font-size: 13px;
  font-family: "Poppins";
  margin: 0;
  padding: 12px 0px 6px 0px;
}

.miniosk-input {
  width: 30vw;
  height: 6.5vh;
  border-radius: 8px;
  color: black;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  padding-left: 12px;
  border: 1px solid #201c1c3d;
}

.miniosk-input-phone {
  width: 40rem;
  /* height: 6.5vh; */
  height: 4rem;
  border-radius: 8px;
  color: black;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  padding-left: 12px;
  border: 1px solid #201c1c3d;
}

.continue-create-button {
  width: 30vw;
  height: 7vh;
  border-radius: 8px;
  border-width: 1px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  margin-top: 20px;
  background-color: #8b5cff;
  border: none;
}

.google-button {
  width: 30vw;
  height: 7vh;
  border-radius: 8px;
  border: 1px solid #201c1c3d;
  cursor: pointer;
  color: #201c1c;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  margin-top: 20px;
  background-color: #fff;
}

.radio-input {
  font-size: 13px;
  font-family: "poppins";
}

.kiosk_imgDiv {
  position: absolute;
  bottom: 6px;
  left: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.reg-logo {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.miniosk-img {
  width: 30px;
}
.humanly-text {
  font-size: 15px;
  font-weight: bold;
  font-family: "Poppins";
}
.checkboxDiv {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}
.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.container-hide {
  display: none;
}

.header-form-container {
  margin: auto;
}

/* .css-1ixds2g {
  height: 6.5vh !important;
  padding: 0px 14px 0px 0px !important;
} */

@media (max-width: 450px) {
  .miniosk-input-phone {
    width: 20rem;
  }
  .form-container-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container-hide {
    display: block;
  }
  .header-form-container {
    margin: auto;
  }
}

@media (min-width: 1280px) and (max-width: 1920px) {
  .miniosk-input-phone {
    width: 37rem;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .miniosk-input-phone {
    width: 32rem;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .miniosk-input-phone {
    width: 28rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .miniosk-input-phone {
    width: 24rem;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .miniosk-input-phone {
    width: 18rem;
  }
}
