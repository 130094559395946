html,
body {
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure body takes full height */
}

.container-image {
    flex: 1; /* Allow this to grow as well */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.grid-container-item {
    padding: 8vh;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center;
}

.email-textfield {
    width: 40rem;
}

.continue-button-width {
    width: 40rem;
}

.container-hide {
    display: none;
}

.header-form-container {
    margin: auto;
}

/* Responsive adjustments for small screens */
@media (max-width: 450px) {
    .grid-container-item {
        padding: 2vh;
    }
    .container-hide {
        display: block;
    }
    .header-form-container {
        margin: auto;
    }
    .kiosk_image_logo {
        display: none;
    }
    .header-text {
        text-align: center;
    }
    .email-textfield {
        width: 20rem;
    }
    .email-header {
        text-align: left;
    }
    .continue-button-width {
        width: 20rem;
    }
    .form-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1280px) and (max-width: 1920px) {
    .email-textfield {
        width: 37rem;
    }
    .continue-button-width {
        width: 37rem;
    }
    .grid-container-item {
        padding: 4vh;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .email-textfield {
        width: 32rem;
    }
    .continue-button-width {
        width: 32rem;
    }
    .grid-container-item {
        padding: 4vh;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .email-textfield {
        width: 28rem;
    }
    .continue-button-width {
        width: 28rem;
    }
    .grid-container-item {
        padding: 4vh;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .email-textfield {
        width: 24rem;
    }
    .continue-button-width {
        width: 24rem;
    }
    .grid-container-item {
        padding: 4vh;
    }
}

@media (min-width: 600px) and (max-width: 768px) {
    .email-textfield {
        width: 18rem;
    }
    .continue-button-width {
        width: 18rem;
    }
    .grid-container-item {
        padding: 1vh;
    }
}