html,

body {
    margin: 0;
    padding: 0;
}

.dashboard-container {
    /* background-color: #FFFFFF; */
    /* height: 100%; */
    flex-direction: column;

}

.insight-title-heading {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 28px;
    color: #201C1C;
    /* margin: 10px; */
}

.insight-sub-title {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    margin-top: 0;
    color: #201C1C;
}

.details-container {
    /* width: 78vw; */
    /* height: 80vh; */
    background-color: white;
    padding-top: 16px;
    border-radius: 8px;
    padding-bottom: 24px;
    margin-right: 10px;
}

.details-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 24px;
    padding-left: 20px;
    padding-right: 20px;
}

.input-label {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
}

.card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 24px;
    padding-right: 20px;
}

.card-container-sub-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 20px;
    padding-top: 24px;
    padding-right: 20px;
    align-items: center;
}

.card {
    /* display: flex;
    height: 23.01vh;
    border: 1px solid;
    width: 20.98vw;
    border-radius: 8px;
    border-color: #201C1C0A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    /* padding-top: 16px; */

    display: flex;
    height: 148px;
    padding: 16px 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid rgba(32, 28, 28, 0.04);
    background: #FFF;
}

.img-container {
    background-color: #FBFBFB;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    margin: 0;
}

.no-background {
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.content-count {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins';
    margin: 0;
    padding: 0;
    padding-top: 10px;
}

.content-para {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
    color: #201C1C70;
    margin: 0;
    padding: 0;
    text-align: center;
    padding-top: 8px;
}

.sub-card {
    display: flex;
    height: 23.01vh;
    border: 1px solid;
    width: 15.51vw;
    border-radius: 8px;
    border-color: #201C1C0A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
}

.sub-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 78vw; */
    margin-right: 10px;
}

.sales-header {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
}

.sales-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.filter-btn {
    width: 125px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-color: #201C1C52; */
    border: none;
    color: #201C1CB8;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Poppins';
}

.download {
    width: 125px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #8B5CFF;
    color: #FFFFFF;
    border-width: 0px;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Poppins';
}

.btn-pad {
    padding-right: 16px;
    justify-content: center;
    align-items: center;
}

.table-container {
    /* width: 78vw; */
    /* height: 10vh; */
    background-color: #FFFFFF;
    border-radius: 8px;
}