.mainContainer-behaviour {
    padding-top: 20px;
    padding-left: 15px;
    text-align: start;
}

.behaviour-content {
    font-weight: bold;
    font-size: 28px;
    font-family: 'Poppins';
    margin: 0;
    letter-spacing: 1px;
}

.settingToControl {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    color: #858585;
    font-weight: 400;
}

.mainbox {
    background-color: white;
    height: auto;
    margin-top: 20px;
    padding: 10px 0px 10px 15px;
    width: 62%;
    border-radius: 8px;
}

.labelForBehaviour {
    display: flex;
    margin-top: 10px;
    gap: 7px;
    font-weight: 400;
    margin: 0;
}

.about-item-behaviour {
    font-size: 10px;
    font-family: 'Poppins';
    margin: 0;
    color: #201C1CB8;
    padding-top: 4px;
    font-weight: 400;
}

.cards {
    background-color: white;
    height: auto;
    margin-top: 15px;
    padding: 8px 0px 10px 15px;
    width: 47.73vw;
    border-radius: 8px;

}

.instructions-behaviour {
    padding-top: 16px;
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    font-weight: 500;
    color: #201C1C;
    width: 44.94vw;
}
.info-inst {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    font-weight: 500;
    color: #201C1C;
    width: 44.94vw;
}

.checkbox-content {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    font-weight: 500;
}

.aboutthebehaviourr {
    font-size: 10px;
    font-family: 'Poppins';
    margin: 0;
    color: #201C1CB8;
    font-weight: 400;
    margin-top: 5px;
    width: 44.94vw;

}

.idleScreen {
    font-size: 14px;
    font-family: 'Poppins';
    margin: 0;
    color: #ACAAAA;
    font-weight: 400;
    padding-top: 16px;
}
.delete-con-behaviour{
    background-color: #8B5CFF0A;
    height: 30.08px;
    width: 30.08px;
    border-radius: 22.55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textarea-behaviour {
    width: 45vw;
    height: 150px;
    padding: 2px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    outline: none;
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    margin-top: 10px;
    padding: 10px;
    font-weight: 400;
}

.uploadbg-btn {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    height: 33px;
    width: 200px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
}

.manual-btn {
    cursor: pointer;
    color: white;
    background-color: #8B5CFF;
    font-size: 14px;
    font-family: 'Poppins';
    margin: 0;
    height: 33px;
    width: 240px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
}

.checkoutflow {
    font-weight: bold;
    font-size: 24px;
    font-family: 'Poppins';
    margin: 0;
    letter-spacing: 1px;
    padding-top: 20px;
}

.steps {
    font-weight: bold;
    font-size: 16px;
    font-family: 'Poppins';
    margin: 0;
    letter-spacing: 1px;
}

.customize {
    font-size: 10px;
    font-family: 'Poppins';
    margin: 0;
    color: #858585;
    font-weight: 400;
    width: 60%;
}

.aboutSteps {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    color: #201C1CB8;
    font-weight: 400;
    padding-top: 5px;
}