.kiosk_imgDiv {
  position: absolute;
  bottom: 6px;
  left: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.main-container-otp {
  /* width: 40%; */
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 60px;
}

.miniosk-img {
  width: 30px;
}

.humanly-text {
  font-size: 15px;
  font-weight: bold;
  font-family: "Poppins";
}

.email-div {
  font-size: 14px;
  color: #8b5cff;
  font-family: "Poppins";
  margin: 0;
}

.enter-miniosk {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  color: #9c9c9c;
  margin: 0;
  padding-top: 30px;
}

.continue-btn {
  width: 396.2px;
  height: 48.65px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  font-family: 100;
  font-size: 14px;
  margin-top: 20px;
  background-color: #8b5cff;
  font-family: "Poppins";
}

.continue-btn-otp-container {
  display: flex;
  justify-content: flex-start;
  width: 29vw;
  height: 8vh;
}
.miniosk-login-verifivation {
  color: #8b5cff;
  font-weight: 400;
  font-size: 18px;
  font-family: Poppins;
  margin-top: 5px;
}

.container-width {
  width: 30rem;
}

.continue-button-otp {
  width: 30rem;
}

.container-hide {
  display: none;
}

.header-form-container {
  margin: auto;
}

/* Responsive adjustments for small screens */
@media (max-width: 450px) {
  .container-width {
    width: 20rem;
  }

  .continue-button-otp {
    width: 20rem;
  }

  .subtext {
    justify-content: center;
  }

  .container-hide {
    display: block;
  }
  
  .header-form-container {
    margin: auto;
  }
}

@media (min-width: 1280px) and (max-width: 1920px) {
  .container-width {
    width: 30rem;
  }

  .continue-button-otp {
    width: 30rem;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .container-width {
    width: 20rem;
  }

  .continue-button-otp {
    width: 20rem;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .container-width {
    width: 20rem;
  }

  .continue-button-otp {
    width: 20rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container-width {
    width: 20rem;
  }

  .continue-button-otp {
    width: 20rem;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .container-width {
    width: 20rem;
  }

  .continue-button-otp {
    width: 20rem;
  }
}
