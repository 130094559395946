html,

body {
    margin: 0;
    padding: 0;
}

.container {
    background-color: #FBFBFB;
    height: 100%;
}
.navigation-container{
    margin: 0;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.back{
    margin: 0;
    padding-left: 20px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins';
}
