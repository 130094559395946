html,
body {
  margin: 0;
  padding: 0;
}

.container {
  background-color: #fbfbfb;
  height: 100%;
  flex-direction: column;
}

.title-heading {
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 28px;
}

.manage-tabs-container {
  width: 78vw;
  height: 10.47vh;
  background-color: #ffffff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.active-button {
  width: 8.34vw;
  height: 6.21vh;
  border-radius: 8px;
  border: 0;
  background-color: #8b5cff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}
.inactive-button {
  width: 8.34vw;
  height: 6.21vh;
  border-radius: 8px;
  border: 0;
  background-color: #201c1c52;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: #201c1c;
}
.OverviewMenu-drop {
  border: 1px solid #201c1c52;
  display: flex;
  padding: 10px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: "center";
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.miniosk-table-container {
  width: 78vw;
  margin-top: 16px;
}
/*  */
/* 

.timeslots-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeslot-input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.timeslot-input {
    margin-top: 8px;
    width: 19.98vw;
    height: 7.20vh;
    border-radius: 16px;
    border-width: 1px;
    border-color: #201C1C3D;
    padding: 10px 16px 10px 16px;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    font-family: Poppins;
    margin-right: 16px;
}

.plus-button {
    height: 28px;
    width: 28px;
    border-radius: 6px;
    background-color: #8B5CFF;
    justify-content: right;
    align-items: right;
    display: flex;
    cursor: pointer;
} */
