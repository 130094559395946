html,
body {
  margin: 0;
  padding: 0;
}

.container {
  background-color: #fbfbfb;
  /* height: 100%; */
  flex-direction: column;
  padding-bottom: 20px;
}

.menu-id {
  margin: 0;
  /* padding-top: 16px; */
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins";
  width: 1000px;
}

.desc {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  color: #201c1c70;
}

.download-qr {
  width: 134px;
  height: 36px;
  justify-content: center;
  align-items: center;
  background-color: #8b5cff;
  color: #ffffff;
  border-width: 0px;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins";
  border-radius: 8px;
  margin-top: 16px;
}

.content-heading {
  margin: 0;
  padding-top: 20px;
  font-weight: 600;
  font-size: 18px;
  font-family: "Poppins";
}

.content-desc {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  color: #201c1c7a;
  width: 47.73vw;
}

.menu-item-container {
  /* margin: 0; */
  padding-top: 24px;
  /* position: relative; */
}

.hot-selling {
  display: flex;
  align-items: center;
}

.hot-selling-sub {
  display: flex;
  align-items: center;
}

.deails-row {
  width: 52.41vw;
  height: 9.16vh;
  align-items: center;
  padding: 16px;
  border-width: 1;
  border-color: #201c1c52;
  display: flex;
  justify-content: space-between;
}

.summaryContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.deails-row-sub {
  width: 49.63vw;
  /* height: 9.81vh; */
  /* padding-left: 16px; */
  border-width: 1;
  border-color: #201c1c52;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: column;
}

.detiails-sub-container {
  align-items: center;
  width: 49.63vw;
  height: 9.81vh;
  display: flex;
  justify-content: space-between;
  border-color: #201c1c52;
  border-width: 1px;
  border-radius: 8px;
}

.item-list-container {
  width: 590px;
  height: 9.16vh;
  align-items: center;
  padding-left: 16px;
  border-radius: 8px;
  border-width: 1;
  border-color: #201c1c52;
  display: flex;
  justify-content: space-between;
}

.operate-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hot-selling-header {
  margin: 0;
  padding-left: 10px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins";
}

.show-btn {
  display: flex;
  align-items: center;
  border: 0;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins";
  color: #201c1cb8;
  cursor: pointer;
}

.category-container {
  margin: 0;
}

.category-desc {
  margin: 0;
  padding-left: 10px;
  font-weight: 400;
  font-size: 8px;
  font-family: "Poppins";
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-content div:last-child {
  border-bottom: none;
}

.dropdown-content div:hover {
  background-color: #f2f2f2;
}

.price {
  margin: 0;
  color: #8b5cff;
  font-weight: 500;
  font-size: 12px;
  font-family: "Poppins";
  display: flex;
  align-items: center;
}

.item-transition {
  transition: all 0.6s ease;
}

.category-items {
  display: flex;
}

.item-list-container {
  width: 45.68vw;
  height: 8.51vh;
  align-items: center;
  border-width: 1;
  border-color: #201c1c52;
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfb;
}

.item-list-sub-container {
  background-color: #fbfbfb;
}

.list-title {
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins";
}

.category-item {
  /* width: 49.63vw;
    height: 9.81vh;
    align-items: center;
    border-width: 1;
    border-color: #201C1C52;
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF; */
}

.item-list-details {
  width: 45.68vw;
  /* height: 6.54vh; */
  /* align-items: center; */
  border-width: 1;
  border-color: #201c1c52;
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfb;
  border-radius: 8px;
}

.allergen-card-container {
  margin-top: 20px;
  width: 66.62vw;
  height: 30.76vh;
  background-color: #ffffff;
  border-radius: 8px;
}

.allergen-card-sub-container {
  padding-left: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.card-details {
  background-color: #fbfbfb;
  border-radius: 8px;
  border-width: 1px;
  border-color: #201c1c52;
  width: 15.3vw;
  height: 17.02vh;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
  display: flex;
}

.img-container-allergen {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.allergen-details {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.allergen-details-sub {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.allregen-header {
  margin: 0;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins";
  padding-top: 2px;
}

.allergen-sub-header {
  font-weight: 400;
  font-size: 10px;
  font-family: "Poppins";
  color: #201c1c70;
  margin: 0;
}

.edit-menu-container {
  background-color: #8b5cff0a;
  height: 30.08px;
  width: 30.08px;
  border-radius: 22.55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container-allergen {
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-radius: 22.55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allergen-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.create-new-btn {
  width: 8.93vw;
  height: 5.89vh;
  justify-content: center;
  align-items: center;
  background-color: #8b5cff;
  color: #ffffff;
  border-width: 0px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  border-radius: 8px;
  margin-top: 16px;
  margin-left: 16px;
}

.photo-container {
  width: 7.65vw;
  height: 17.01vh;
  background-color: #d9d9d9;
  border-radius: 6px;
}

.menu-title-header {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins";
}

.title-sub-text {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins";
  color: #201c1cb8;
}
.Loader-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
}

.css-4jnixx-MuiStack-root {
  width: 100%;
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.tag-save-button {
  width: 5.69vw;
  height: 3.89vh;
  background-color: #8b5cff;
  border-radius: 8px;
  border: 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.tag-cancel-button {
  width: 5.69vw;
  height: 3.89vh;
  background-color: #ffffff;
  border-radius: 8px;
  border: 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #f34a4a;
  margin-left: 16px;
}
