.mainContainer {
    padding-top: 20px;
    padding-left: 15px;
    text-align: start;
    padding-bottom: 20px;
}

.mian-content {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    /* line-height: 32px; */
    /* 114.286% */
}

.color-pick {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.chrome-picker {
    width: 100% !important;
}

.mian-sub-content {
    color: rgba(32, 28, 28, 0.48);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 16px; */
    /* 114.286% */
    margin: 0;
    width: 80%;
}

.advance-content {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 16px; */
    /* 133.333% */
    text-transform: capitalize;
    margin: 0;
}

.advance-sub-content {
    color: rgba(32, 28, 28, 0.48);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding-top: 8px;
    /* line-height: 16px; */
    /* 114.286% */
}

.display-content {
    color: #201C1C;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
    text-transform: capitalize;
}

.cards-sub-content {
    color: rgba(32, 28, 28, 0.44);
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 140% */
}

.display-sub-content {
    color: rgba(32, 28, 28, 0.48);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}

.cards-title {
    color: #201C1C;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 200% */
    text-transform: capitalize;
}

.color-picker-card {
    margin-top: 1rem;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.checkbox-label {
    display: flex;
    margin-top: 10px;
    gap: 7px;
    font-weight: normal;
    font-family: 'Poppins';
    color: #201C1C;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 200% */
    text-transform: capitalize;
}

.cards {
    background-color: white;
    height: auto;
    margin-top: 15px;
    padding: 8px 0px 10px 15px;
    width: 62%;
    border-radius: 8px;
}

.checkbox-content {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    font-weight: normal;
}

.input-sub-lable {
    font-size: 10px;
    font-family: 'Poppins';
    margin: 0;
    color: #b5b4b4;
    font-weight: normal;
    margin-top: 5px;
}