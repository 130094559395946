.radioButton_maindiv{
    display: flex;
    flex-direction: column;
    gap: 10px; 
}
.labelForradio{
    display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}
.textForRadio-btn{
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 400;
}
.kiosk_imgDiv{
    position: absolute;
    bottom: 6px;
    left: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.miniosk-img{
    width: 30px;
}
.humanly-text{
    font-size: 15px;
    font-weight: bold;
    font-family: 'Poppins';
}

.continue-button-grow {
    width: 25rem;
}

/* Responsive adjustments for small screens */
@media (max-width: 450px) {
    .continue-button-grow {
        width: 20rem;
    }
}

@media (min-width: 1280px) and (max-width: 1920px) {
    .continue-button-grow {
        width: 25rem;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .continue-button-grow {
        width: 25rem;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .continue-button-grow {
        width: 25rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .continue-button-grow {
        width: 20rem;
    }
}

@media (min-width: 600px) and (max-width: 768px) {
    .continue-button-grow {
        width: 20rem;
    }
}

@media (min-width: 540px) and (max-width: 600px) {
    .continue-button-grow {
        width: 25rem;
    }
}