html,

body {
    margin: 0;
    padding: 0;
}

.container {
    background-color: #FBFBFB;
    height: 100%;
    flex-direction: column;
}

.promo-creation-title {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 28px;
    margin: 0;
}

.promotion-creation-container {
    width: 48.56vw;
    border-radius: 8px;
    padding: 20px;
    background-color: #FFFFFF;
    margin-top: 16px;
    margin-bottom: 16px;
}

.promotion-list-title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Poppins';
}

.promotion-list-sub-title {
    margin: 0;
    font-weight: 400;
    font-size: 10px;
    font-family: 'Poppins';
    color: #201C1CB8;
}

.switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.switch-position-container {
    display: flex;
    flex-direction: column;
}

.promo-item-list {
    margin: 0;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 12px;
}


.promo-list-category-container {
    display: flex;
    justify-content: space-between;
    width: 44.87vw;
    height: 8.51vh;
    border-radius: 8px;
    border: #201C1C14 1px solid;
    margin-top: 8px;
    padding: 13px, 16px, 13px, 16px;
    display: flex;
    align-items: center;
}

.upload-photo {
    width: 16.69vw;
    height: 5.89vh;
    justify-content: center;
    align-items: center;
    background-color: #8B5CFF;
    color: #FFFFFF;
    border-width: 0px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Poppins';
    border-radius: 8px;
    margin-top: 16px;
}

.promotion-list-container-sub-title {
    font-weight: 600;
    font-size: 16px;
    font-family: 'Poppins';
    margin: 0;
}

.promo-item-switch-btn {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0;
    color: #201C1C;
}

.promo-item-switch-btn-sub {
    font-weight: 400;
    font-size: 10px;
    font-family: 'Poppins';
    margin: 0;
    color: #201C1C70;
}

.input-container-promo-creation {
    /* margin-top: 8px; */
    width: 44.94vw;
    height: 6.20vh;
    border-radius: 8px;
    border-width: 1px;
    border-color: #201C1C3D;
    /* padding: 10px 16px 10px 16px; */
    outline: none;
    font-family: 'Poppins';
}