.heading {
  margin-top: 20px;
  font-family: "Poppins";
  font-weight: 800;
  font-size: 16px;
}
.basic-info {
  background: #ffffff;
  margin-top: 16px;
  width: 660px;
  height: 230px;
  border-radius: 24px;
  padding: 20px;
}
.location-name {
  /* margin-top: 8px; */
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}
.location-input {
  margin-top: 8px;
  width: 614px;
  height: 44px;
  border-radius: 8px;
  border-color: #201c1c3d;
  border-width: 1px;
  outline: none;
  font-weight: 500;
  font-family: "Poppins";
  font-size: 14px;
  padding: 0 7px;
}
.nick-name {
  margin-top: 16px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}
.business {
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins";
}
.location-input1 {
  margin-top: 8px;
  width: 614px;
  height: 130px;
  border-radius: 8px;
  border-color: #201c1c3d;
  border-width: 1px;
  outline: none;
  font-weight: 500;
  font-family: "Poppins";
  font-size: 14px;
  padding: 0 7px;
}
.location {
  background: #ffffff;
  margin-top: 16px;
  width: 660px;
  height: 110px;
  border-radius: 24px;
  padding: 20px;
}

.basic-info1 {
  background: #ffffff;
  margin-top: 16px;
  width: 660px;
  height: 480px;
  border-radius: 24px;
  padding: 20px;
}
